@import "../../styles/variables.scss";
.Calendar {
  height: calc(100vh - 80px);
  max-width: 100%;
  .title-bar {
    height: 3rem;
    .day-title {
      background-color: $primary;
      filter: brightness(0.95);
      color: $tablebkg;
      width: calc(100% / 7);
      border: 1px solid rgba(88, 88, 88, 0.4);
    }
  }
  .days {
    height: calc(100% - 0.5rem);
    .day {
      padding: 0;
      width: calc(100% / 7);
      background-color: $tablebkg;
      .day-content {
        border: 1px solid rgba(88, 88, 88, 0.4);
        height: 100%;
        .day-number {
          margin-top: 5px;
          border: 1px solid #aeaeae;
          border-radius: 50%;
          height: fit-content;
          width: 25px;
          color: $text;
          &.today {
            background-color: $primary;
            color: $bkg;
          }
        }
        .tournamentList {
          margin-top: 10px;
          width: 100%;
          .tournament-name {
            cursor: pointer;
            &:hover {
              filter: brightness(85%);
            }
          }
        }
      }
      &.empty {
        filter: brightness(70%);
      }
    }
  }
}
