// scrollbar
/* width */
::-webkit-scrollbar {
  width: 10px;
  background-color: rgba(35, 49, 86, 0.4);
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: rgba(35, 49, 86, 0.8);
  border-radius: 6px;
  &:hover {
    background-color: rgba(35, 49, 86, 1);
  }
}

html {
  background-color: rgba(46, 49, 49, 1);
}