.tournament-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  overflow: hidden;
  .card {
    max-width: 1000px;
    .tournament-link:hover {
      filter: brightness(85%);
    }
    .edit-link-icon-container {
      margin-left: 15px;
    }
    .close-icon,
    .edit-link-icon {
      cursor: pointer;
      color: rgba(56, 56, 56, 0.8);
      transition: 200ms;
      &:hover {
        color: rgba(56, 56, 56, 1);
        transform: scale(1.1);
      }
    }
    .box {
      min-width: 500px;
      padding-bottom: 20px;
      padding-left: 40px;
      padding-right: 40px;
      background: #fff;
      text-align: center;
      transition: 0.25s;
      .color-picker {
        width: 60px;
      }
      @media (max-width: 768px) {
        padding-left: 0;
        padding-right: 0;
        min-width: 100% !important;
        .color-picker {
          margin-right: 33%;
          margin-left: 33%;
          width: 33%;
        }
        .text-input {
          margin-right: 25%;
          margin-left: 25%;
          width: 50%;
        }
      }
    }
    h1 {
      text-transform: uppercase;
      font-weight: 500;
      font-size: 28px;
    }
    .submit-button {
      border: 0;
      display: block;
      margin: 20px auto;
      text-align: center;
      padding: 14px 40px;
      outline: none;
      color: white;
      background-color: #2ecc71;
      border-radius: 24px;
      transition: 0.25s;
      cursor: pointer;
      &:hover {
        background-color: #25a85c;
      }
    }
    .delete-button {
      border: 0;
      display: block;
      margin: 20px auto;
      text-align: center;
      padding: 14px 40px;
      outline: none;
      color: white;
      background-color: #cc2e2e;
      border-radius: 24px;
      transition: 0.25s;
      cursor: pointer;
      &:hover {
        background-color: #b62a2a;
      }
    }
    .datepicker {
      .rdrDefinedRangesWrapper {
        display: none;
      }
    }
  }

  @media (max-height: 700px) {
    overflow-y: scroll;
  }
}
