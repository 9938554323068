@import "../../styles/variables.scss";

.desktop-header {
  //base settings
  background-color: $bkg;
  box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.5);
  min-height: 40px;
  // links
  .nav-item {
    font-size: 16px;
    font-weight: 500;
    .btn:focus {
      box-shadow: none;
    }
    a {
      color: $secondary;
      // border animations
      &:after {
        display: block;
        content: "";
        border-bottom: solid 2px $primary;
        transform: scaleX(0);
        transition: transform 250ms ease-in-out;
        transform-origin: 100% 0%;
      }
      &.active {
        color: $primary;
        &:after {
          transform: scaleX(1);
        }
      }
      &:hover {
        color: $primary;
        &:after {
          transform: scaleX(1);
          transform-origin: 0% 50%;
        }
      }
    }
  }
}
